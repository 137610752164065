import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from '../components/layout/layout'
import Meta from '../components/meta/meta'

interface Props {
  location: Location
}

const FAQ: React.FC<Props> = ({ location }: Props) => {
  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="FAQs" />
      <div className="section shape5 d-flex container-fluid">
        <div className="container-xl py-10 mb-5">
          <div className="container-xs text-center">
            <h1>FAQs</h1>
            <p className="font-size-lg px-2">
              We're sure you have a ton of questions — we'll start by answering
              a few of the ones we hear most.
            </p>
          </div>
        </div>
      </div>
      <div className="section shape18 bg-dark text-light bg4 d-flex align-items-center container-fluid">
        <div className="container-xl py-10">
          <div className="container-sm m-0">
            <h4 className="mt-5">
              Sodality is a unique word. Why did you choose it as a name?
            </h4>
            <p className="mb-5 text-muted">
              All we had to do was find a word or phrase that represented us as
              individuals, our aspirations, our goals, and of course be easy to
              remember and readily available from a domain standpoint. As we
              were browsing through the thesaurus starting with "community",
              eventually Sodality popped out. We'd like to think that it does a
              pretty okay job fulfilling the tall order above.
            </p>
            <h4>Do I need to be in the Denver area to work with you?</h4>
            <p className="mb-5 text-muted">Nope.</p>
            <h4>Do I need to have an idea already to work with you?</h4>
            <p className="mb-5 text-muted">
              When we say we want to work with you as early as possible, we mean
              it! We believe that innovative solutions to important problems are
              best formed through real world experimentation and iteration - in
              other words, our hunch is that your “idea” is going to evolve
              significantly over time, so don’t let the fact that it does not
              yet feel fully fleshed out hold you back from applying. That said,
              we do expect that you have developed strong conviction and unique
              insight around the problem you want to solve.
            </p>
            <h4>What if I've already taken previous capital?</h4>
            <p className="mb-5 text-muted">
              Depending on the type of capital, this may be fine. If you've
              already taken previous traditional Venture Capital, we might not
              be the right solution for you but if what we're doing is
              intriguing to you, don't let that deter you from reaching out.
            </p>
            <h4>
              You mention maximizing the amount of business I own. Can you
              elaborate?
            </h4>
            <p className="mb-5 text-muted">
              With traditional VC once you take money, the ownership, voting
              rights, and control you gave up you will likely never see again.
              As we've mentioned before, this model might make sense for some
              people but likely not for most. With our model, you're given the
              chance to "buy back" a majority of the stake you sold during our
              round as your business achieves greater and greater levels of
              success. If all goes well, you have the potential of reaping all
              of the benefits of raising capital whilst having the opportunity
              to continue to maintain control of the direction of your company.
              This is not a requirement but we think that having optionality is
              another one of the benefits we can offer.
            </p>
            <h4>How much money we talkin' here?</h4>
            <p className="mb-5 text-muted">
              Typical investments range from $150k-$250k depending on factors.
              Larger or smaller investments might be appropriate depending on
              your situation.
            </p>
            <h4>What kind of exit would be interesting to you as investors?</h4>
            <p className="mb-5 text-muted">
              VC understandably focuses on exits or opportunities for investors
              to get their money back at a multiplier. Consequently, there is
              little interest in companies that have little interest in an exit.
              This is one of the largest differentiators between us and a
              traditional VC. While exits are certainly not frowned upon, if the
              result of your business is a growing and profitable company, we
              consider that a success.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FAQ
